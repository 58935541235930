import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DangerTile from './dangerTile';
import DangerSwitcher from './dangerSwitcher';
import { colors } from '../../../theme';

interface Props {
    name: string;
    danger: boolean;
    onChange: (checked: boolean) => void;
}

function ZoneTile({ name, danger, onChange }: Props) {
  const { t } = useTranslation();
  return (
    <DangerTile danger={danger}>
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="200px">
        <Typography color={danger ? colors.grayscale.b1 : colors.grayscale.w0} variant="h3" fontWeight="bold">{name}</Typography>
        <Box>
          <DangerSwitcher checked={danger} onChange={() => onChange(!danger)} />
          <Typography color={danger ? colors.grayscale.b1 : colors.grayscale.w1} mt={2}>{danger ? t('there_is_a_threat') : t('no_threat')}</Typography>
        </Box>
      </Box>
    </DangerTile>
  );
}

export default ZoneTile;
