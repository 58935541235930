import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import CrewStatusSwitcher from 'features/missions/components/crewStatusSwitcher';
import { crewStatus, crewStatuses } from 'features/crews/services/constants';
import HeaderLayout from 'components/header/headerLayout';
import { ICrew, TCrewStatus } from 'types/crew';
import api, { apiURLs } from 'services/api';
import { colors } from 'theme';
import { last } from 'lodash';
import StatusLabelDropdown from 'components/statusLabelDropdown';
import NoCrew from '../components/noCrew';
import ReasonAlert from '../../../components/reasonAlert';

function PilotsCrew() {
  const { t } = useTranslation();

  const {
    data: crew,
  } = useQuery({
    queryKey: ['my-crew'],
    queryFn: (): Promise<ICrew> => api.get(apiURLs.crews.my)
      .then((res) => res.data)
      .catch((err) => {
        console.error(err);
      }),
  });

  if (!crew) {
    return (
      <HeaderLayout
        menu="pilot"
        empty
        statusSwitcher={<Typography color={colors.grayscale.g1}>{t('you_dont_have_crew')} 😢</Typography>}
      >
        <NoCrew />
      </HeaderLayout>
    );
  }

  return (
    <HeaderLayout
      menu="pilot"
      statusSwitcher={(
        <CrewStatusSwitcher
          crew={crew}
          options={crewStatuses as TCrewStatus[]}
        />
      )}
    >
      <Box mt={5} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2" color={colors.grayscale.g1}>{t('crew_details')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1">{crew?.name}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography color={colors.grayscale.g2}>{t('type')}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography>{crew?.type}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography color={colors.grayscale.g2}>{t('status')}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <StatusLabelDropdown
            currentStatus={crew?.status as TCrewStatus}
            id="crew-status"
            type="crew"
          />
        </Grid>
        {crew.status === crewStatus.NOT_READY && (
        <>
          <Grid item xs={12} md={6}>
            <ReasonAlert
              reason={last(crew.reports)}
              color="red"
              title={t('reason_crew_not_ready')}
            />
          </Grid>
          <Grid item md={6} />
        </>
        )}
        <Grid item xs={12} md={2}>
          <Typography color={colors.grayscale.g2}>{t('location')}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography>{crew?.location?.name}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography color={colors.grayscale.g2}>{t('uav')}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography>{crew?.uav?.map((uav) => uav.name).join(', ')}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography color={colors.grayscale.g2}>{t('ammunition')}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography>{crew?.ammo?.map((ammo) => ammo.name).join(', ')}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography color={colors.grayscale.g2}>{t('crew_members')}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography>{crew?.members?.map((member) => member.username).join(', ')}</Typography>
        </Grid>
      </Grid>
    </HeaderLayout>
  );
}

export default PilotsCrew;
