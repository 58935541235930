export const role = {
  CREW_MEMBER: 'crew_member',
  ISTAR: 'istar',
  BATTLE_CAPTAIN: 'battle_captain',
  ADMIN: 'admin',
  AIR_DEFENCE: 'air_defence',
};

export const roles = [
  role.CREW_MEMBER,
  role.ISTAR,
  role.BATTLE_CAPTAIN,
  role.ADMIN,
  role.AIR_DEFENCE,
];

export const status = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};
