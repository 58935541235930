import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
  Card, Divider, Grid, IconButton, Link, Stack, styled, Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { IGetMissionListResponse, IMission, TMissionStatus } from 'types/mission';
import { urls } from 'router';
import api, { apiURLs } from 'services/api';
import { colors } from 'theme';
import { missionStatus, missionStatuses } from 'constants/mission';

import TargetCardAccordion from 'features/missions/components/targetCardAccordion';
import MissionCardZone from 'features/missions/components/missionCardZone';
import ReasonAlert from 'components/reasonAlert';
import MissionTypeIcon from 'components/missionTypeIcon';
import StatusLabelDropdown from 'components/statusLabelDropdown';
import MissionActionButton, { StatusWorkflow } from './missionActionButton';
import EndMissionConfirmation from './endMissionConfirmation';

interface IProps {
  mission: IMission
  setSelectedMission: (id: string | null) => void
}

const DetailsCard = styled(Card)(() => ({
  padding: '30px',
  border: 'none',
  backgroundColor: colors.grayscale.b2,
}));

function MissionDetailsSidebar(
  {
    mission,
    setSelectedMission,
  }: IProps,
) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [statusConfirmationOpen, setStatusConfirmationOpen] = React.useState(false);
  const [suspendConfirmationOpen, setSuspendConfirmationOpen] = React.useState(false);

  const { crew } = mission;
  const { location } = crew;
  const controlPointDistance = location?.control_point_distance || 0;

  const {
    mutate: submit, reset,
  } = useMutation({
    mutationKey: ['submit-status'],
    mutationFn: (m: any) => (api.patch(apiURLs.missions.updateStatus(mission.id), m)
      .then(({ data }) => {
        reset();
        toast.success(t('mission_status_updated'));
        return data;
      })
      .catch((err) => toast.error(err.response.data?.error))
    ),
    onSuccess: (updatedMission: IMission) => queryClient.getQueryCache().findAll({ queryKey: ['mission-list'] }).forEach((x) => {
      queryClient.setQueryData(x.queryKey, (missions: IGetMissionListResponse) => ({
        ...missions,
        results: missions.results.map((m) => {
          if (m.id === updatedMission.id) {
            return updatedMission;
          }
          return m;
        }),
      }));
    }),
  });

  function onStatusClick(status: TMissionStatus) {
    if (mission.status !== status) {
      submit({ status, report: '' });
    }
    setSelectedMission(null);
  }

  function getLastReport() {
    return mission.reports?.length ? mission.reports[mission.reports.length - 1] : undefined;
  }

  return (
    <Stack gap={1} flex={1}>
      <DetailsCard>
        <Stack flexDirection="row" justifyItems="center" justifyContent="space-between" mb={2}>
          <IconButton aria-label="close" onClick={() => setSelectedMission(null)} style={{ padding: '0', color: colors.grayscale.g2 }}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography variant="body2" color={colors.grayscale.g1} mb={1}>{t('mission_details')}</Typography>
            <Link href={urls.missions.details(mission?.id)}><Typography variant="h1" mb={2} pb={1}>{mission.name}</Typography></Link>
          </Stack>
        </Stack>
        <Grid container spacing={1} mb={2}>
          <Grid item xs={6} md={8} mb={2}>
            <Typography variant="body2" mb={1}>{t('type')}</Typography>
            <Stack flexDirection="row" alignItems="center" gap="6px">
              <Typography variant="body1">{t(mission.type)}</Typography>
              <MissionTypeIcon type={mission.type} />
            </Stack>
          </Grid>
          <Grid item xs={6} md={8} mb={2}>
            <Typography variant="body2" mb={1}>{t('status')}</Typography>
            <StatusLabelDropdown
              type="mission"
              currentStatus={mission.status}
              options={missionStatuses}
              id={mission.id}
              onClick={(status: TMissionStatus) => onStatusClick(status)}
            />
          </Grid>
          {!!StatusWorkflow[mission.status] && (
          <Grid item xs={12} mb={2} alignItems="center">
            <MissionActionButton
              status={mission.status}
              onClick={(status: TMissionStatus) => {
                if (status === missionStatus.COMPLETED) {
                  setStatusConfirmationOpen(true);
                  return;
                } if (status === missionStatus.SUSPENDED) {
                  setSuspendConfirmationOpen(true);
                  return;
                }
                onStatusClick(status);
              }}
            />
          </Grid>
          )}
          {[missionStatus.SUSPENDED, missionStatus.NOT_COMPLETED].includes(mission.status) && (
            <Grid item xs={12}>
              <ReasonAlert
                title={t(`reason_mission_${mission.status}`)}
                reason={getLastReport()}
              />
            </Grid>
          )}
        </Grid>
        <Divider style={{ marginBottom: '16px' }} />
        <Grid container spacing={2} mb={2}>
          <Grid item xs={6} mb={2}>
            <Typography variant="body2" mb={1}>{t('location')}</Typography>
            <Typography variant="body1">
              {location?.name}
            </Typography>
          </Grid>
          <Grid item xs={6} mb={2}>
            <Typography variant="body2" mb={1}>{t('control_point_distance')}</Typography>
            <Typography variant="body1">
              {controlPointDistance / 1000} км
            </Typography>
          </Grid>
        </Grid>
        {
          mission.zones?.length && (
            <>
              <Divider style={{ marginBottom: '16px' }} />
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} mb={2}>
                  <Typography variant="body2" mb={1}>{t('zones')}</Typography>
                  <Typography variant="body1">
                    <MissionCardZone mission={mission} />
                  </Typography>
                </Grid>
              </Grid>
            </>
          )
        }
        <Divider style={{ marginBottom: '16px' }} />
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Typography variant="h2" mb={2}>{t('targets')}</Typography>
          <Typography variant="h2" color={colors.grayscale.g1} mb={2}>1</Typography>
        </Stack>
        {mission.target.map((target) => (
          <TargetCardAccordion
            key={target.id}
            target={target}
            locationDistance={controlPointDistance}
            reconTarget={target.app6d_type === 'Орієнтир (точка інтересу)'}
          />
        ))}
      </DetailsCard>
      <EndMissionConfirmation
        open={statusConfirmationOpen}
        onClose={() => setStatusConfirmationOpen(false)}
        mission={mission}
        title={t('finish_mission')}
        subtitle={t('please_select_status')}
        onSave={(status: string, report: string) => {
          submit({ status, report });
          setStatusConfirmationOpen(false);
          setSelectedMission(null);
        }}
      />
      <EndMissionConfirmation
        suspendConfirmation
        status={missionStatus.SUSPENDED}
        open={suspendConfirmationOpen}
        onClose={() => setSuspendConfirmationOpen(false)}
        mission={mission}
        title={t('suspend_work')}
        subtitle={t('please_enter_suspend_reason')}
        onSave={(status: string, report: string) => {
          submit({ status, report });
          setSuspendConfirmationOpen(false);
          setSelectedMission(null);
        }}
      />
    </Stack>
  );
}

export default MissionDetailsSidebar;
