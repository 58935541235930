import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Card, Divider, Grid, Skeleton, Stack, styled, Typography,
} from '@mui/material';

import { IMission } from 'types/mission';
import api, { apiURLs } from 'services/api';
import { urls } from 'router';
import { colors } from 'theme';
import { missionStatuses } from 'constants/mission';
import defaultMissionProps from 'defaults/mission';
import { getDefaultDate } from 'services/dayjs';
import EditButton from 'components/buttons/editButton';
import MissionTypeIcon from 'components/missionTypeIcon';
import DeleteButton from 'components/buttons/deleteButton';
import BackArrowButton from 'components/buttons/backArrowButton';
import UavTypeIcon from 'components/uavTypeIcon';
import StatusLabel from 'components/statusLabelDropdown';
import MissionAudit from '../components/missionAudit';
import TargetCardAccordion from '../components/targetCardAccordion';
import FinishMissionButton from '../components/FinishMissionButton';

const ReportCard = styled(Card)(() => ({
  marginBottom: '8px',
  border: `1px solid ${colors.brand.b2}`,
  backgroundColor: colors.brand.b1,
}));

const defaultMission: IMission = defaultMissionProps;

function DetailsMission() {
  const { id = '' } = useParams();
  const queryClient = useQueryClient();
  const [mission, setMission] = useState<IMission>(defaultMission);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: missionDetails, isFetching: isFetchingMissionDetails, refetch,
  } = useQuery({
    queryKey: [`mission-details-${id}`],
    queryFn: (): Promise<IMission> => api.get(apiURLs.missions.details(id))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  React.useEffect(() => {
    if (missionDetails && !isFetchingMissionDetails) setMission(missionDetails);
  }, [missionDetails, isFetchingMissionDetails]);

  const {
    mutate: submit, reset,
  } = useMutation({
    mutationKey: ['submit-status'],
    mutationFn: (m: any) => (api.patch(apiURLs.missions.updateStatus(mission.id), m)
      .then(({ data }) => {
        reset();
        toast.success(t('mission_status_updated'));
        return data;
      })
      .catch((err) => toast.error(err.response.data?.error))
    ),
    onSuccess: (updatedMission: IMission) => queryClient.setQueryData(['mission-details'], updatedMission),
  });

  const {
    mutate: finishMission,
  } = useMutation({
    mutationKey: ['finish-mission'],
    mutationFn: (m: any) => (api.patch(apiURLs.missions.finishMission(mission.id), m)
      .then(({ data }) => {
        reset();
        toast.success(t('mission_status_updated'));
        return data;
      })
      .catch((err) => toast.error(err.response.data?.error))
    ),
    onSuccess: (updatedMission: IMission) => queryClient.setQueryData(['mission-details'], updatedMission),
  });

  function onStatusClick(status: string) {
    if (mission.status !== status) {
      submit({ status, report: '' });
    }
  }

  if (isFetchingMissionDetails) return <Skeleton height={50} />;

  const controlPointDistance = mission.crew?.location?.control_point_distance || 0;
  const isFinished = mission.finished;

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between" mb="20px">
        <BackArrowButton path={urls.missions.landing} label={t('all_missions')} />
        {!isFinished && (
          <Stack flexDirection="row" gap={1}>
            <EditButton
              onClick={() => navigate(urls.missions.edit(mission.id))}
              disabled={mission.finished}
            />
            <DeleteButton
              onClick={() => finishMission(mission.id)}
              disabled={mission.finished}
            />
          </Stack>
        )}
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" mb="5px">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Typography variant="h1">{mission?.name}</Typography>
          { isFinished && <StatusLabel currentStatus="archived" id={mission.id} type="mission" /> }
        </Stack>
      </Stack>
      <MissionAudit mission={mission} />
      <Grid container spacing={2}>
        <Grid xs={6} item>
          <Grid container mb={3}>
            <Grid item xs={12} mb="20px">
              <Typography variant="h2">{t('details')}</Typography>
            </Grid>
            <Grid item xs={4} mb="10px">
              <Typography variant="body1" color={colors.grayscale.g2}>{t('type')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Stack flexDirection="row" alignItems="center" gap="6px">
                <Typography variant="body1">{t(mission?.type)}</Typography>
                <MissionTypeIcon type={mission.type} />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" color={colors.grayscale.g2}>{t('status')}</Typography>
            </Grid>
            <Grid item xs={8} mb="10px">
              <StatusLabel
                type="mission"
                currentStatus={mission.status}
                options={missionStatuses}
                id={mission.id}
                onClick={(status: string) => onStatusClick(status)}
                selectable={!mission.finished}
              />
            </Grid>
            <Grid item xs={4} mb="10px">
              <Typography variant="body1" color={colors.grayscale.g2}>{t('due_date')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{getDefaultDate(mission.due_date)}</Typography>
            </Grid>
            <Grid item xs={4} mb="10px">
              <Typography variant="body1" color={colors.grayscale.g2}>{t('created')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{getDefaultDate(mission.created_at)}</Typography>
            </Grid>
          </Grid>
          { mission.description && (
            <>
              <Divider style={{ marginBottom: '24px' }} />
              <Grid container mb={3}>
                <Grid item xs={12} mb="20px">
                  <Typography variant="h2">{t('description')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color={colors.grayscale.g2}>{mission.description}</Typography>
                </Grid>
              </Grid>
            </>
          ) }
          <Divider style={{ marginBottom: '24px' }} />
          <Grid container>
            <Grid container mb={3}>
              <Grid item xs={12} mb="20px">
                <Typography variant="h2">{t('crew')}</Typography>
              </Grid>
              <Grid item xs={4} mb="10px">
                <Typography variant="body1" color={colors.grayscale.g2}>{t('name')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{mission.crew.name}</Typography>
              </Grid>
              <Grid item xs={4} mb="10px">
                <Typography variant="body1" color={colors.grayscale.g2}>{t('uav')}</Typography>
              </Grid>
              <Grid item xs={8}>
                {mission?.crew?.uav?.map((uav) => (
                  <Stack flexDirection="row" alignItems="center" gap={1} key={uav.id}>
                    <Typography variant="body1">{uav.name} • {3}/{5} {t('qnty')}</Typography>
                    <UavTypeIcon type={mission.crew?.uav[0]?.type} />
                  </Stack>
                ))}
              </Grid>
              {mission.crew?.ammo?.length > 0 ? (
                <>
                  <Grid item xs={4} mb="10px">
                    <Typography variant="body1" color={colors.grayscale.g2}>{t('ammo')}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {mission.crew?.ammo.map((ammo) => (
                      <Typography variant="body1" key={ammo.id}>
                        {ammo.name} • {Math.floor(Math.random() * ammo.quantity)}/{ammo.quantity} од
                      </Typography>
                    ))}
                  </Grid>
                </>
              ) : null}
              <Grid item xs={4} mb="10px">
                <Typography variant="body1" color={colors.grayscale.g2}>{t('location')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{mission.crew.location.name}</Typography>
              </Grid>
              <Grid item xs={4} mb="10px">
                <Typography variant="body1" color={colors.grayscale.g2}>{t('control_point_distance')}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{controlPointDistance / 1000} {t('km')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {mission.reports && mission.reports[0] && (
            mission.status === 'completed'
            || mission.status === 'not_completed'
            || mission.status === 'suspended'
          ) && (
            <>
              <Divider style={{ marginBottom: '24px' }} />
              <Grid item xs={12} mb="20px">
                <Typography variant="h2">Звіт</Typography>
              </Grid>
              <Grid item xs={12}>
                <ReportCard>
                  <Stack p={2}>
                    <Typography variant="body1">{mission.reports[0]?.text}</Typography>
                  </Stack>
                </ReportCard>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          {mission.type === 'strike' && (
            <>
              <Stack mb={3} flexDirection="row" gap={1}>
                <Typography variant="h2">{t('targets')}</Typography>
                <Typography variant="h2" color={colors.grayscale.g1}>{mission.target.length}</Typography>
              </Stack>
              {mission.target.map((target) => (
                <TargetCardAccordion
                  key={target.id}
                  target={target}
                  locationDistance={controlPointDistance}
                  reconTarget={target.app6d_type === 'Орієнтир (точка інтересу)'}
                />
              ))}
            </>
          )}
          {mission.type === 'recon' && (
            <FinishMissionButton mission={mission} onClose={() => refetch()} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default DetailsMission;
