export interface NavigationItem {
    name: string
    href: string
    permissions: string[]
}

export const topNavigationItems: NavigationItem[] = [
  {
    name: 'overview',
    href: '/',
    permissions: ['overview'],
  },
  {
    name: 'missions',
    href: '/missions',
    permissions: ['missions'],
  },
  {
    name: 'targets',
    href: '/targets',
    permissions: ['targets'],
  },
  {
    name: 'zones',
    href: '/zones',
    permissions: ['zones'],
  },
  {
    name: 'crews',
    href: '/crews',
    permissions: ['crews'],
  },
];

export const adminNavigationItems: NavigationItem[] = [
  {
    name: 'users',
    href: '/admin',
    permissions: ['user_view', 'user_edit', 'user_create', 'user_deactivate', 'user_activate'],
  },
  {
    name: 'uav',
    href: '/admin/uav',
    permissions: ['uav_view', 'uav_edit', 'uav_create', 'uav_delete'],
  },
  {
    name: 'ammunition',
    href: '/admin/ammunition',
    permissions: ['ammunition_view', 'ammunition_edit', 'ammunition_create', 'ammunition_delete'],
  },
  {
    name: 'location',
    href: '/admin/location',
    permissions: ['location_view', 'location_edit', 'location_create', 'location_delete'],
  },
];

export const pilotNavigationItems: NavigationItem[] = [
  {
    name: 'my_missions',
    href: '/pilot/missions',
    permissions: [],
  },
  {
    name: 'my_crew',
    href: '/pilot/crew',
    permissions: [],
  },
];

export const airDefenceItems: NavigationItem[] = [
  {
    name: 'alerts',
    href: '/air_defence/alerts',
    permissions: [],
  },
];
