import React from 'react';
import {
  Navigate, Outlet, Route, Routes,
} from 'react-router-dom';
import Overview from 'features/overview/pages/overview';
import Missions from 'features/missions/pages/missions';
import CreateMissions from 'features/missions/pages/createMission';
import DetailsMission from 'features/missions/pages/detailsMission';
import HeaderLayout from 'components/header/headerLayout';
import CreateLayout from 'components/header/createLayout';
import DetailsLayout from 'components/header/detailsLayout';
import Targets from 'features/targets/pages/targets';
import TargetsImport from 'features/targets/pages/targetsImport';
import TargetDetails from 'features/targets/pages/targetDetails';
import Zones from 'features/zones/pages/zones';
import CreateZone from 'features/zones/pages/createZone';
import EditZone from 'features/zones/pages/editZone';
import Crews from 'features/crews/pages/crews';
import Login from 'features/login/pages/login';
import CreateCrew from 'features/crews/pages/createCrew';
import EditCrew from 'features/crews/pages/editCrew';
import Users from './features/admin/users/pages/users';
import UAV from './features/admin/uav/pages/uav';
import Ammunition from './features/admin/ammunition/pages/ammunition';
import Location from './features/admin/location/pages/location';
import CreateUser from './features/admin/users/pages/createUser';
import CreateUAV from './features/admin/uav/pages/createUAV';
import CreateAmmunition from './features/admin/ammunition/pages/createAmmunition';
import CreateLocation from './features/admin/location/pages/createLocation';
import PrivateRoute from './features/login/privateRoute';
import { role } from './features/admin/users/services/constants';
import PilotsMissions from './features/pilot/pages/pilotsMissions';
import EditMission from './features/missions/pages/editMission';
import PilotsCrew from './features/pilot/pages/pilotsCrew';
import AirDefenceAlerts from './features/air_defence/pages/airDefenceAlerts';
import PrivacyPolicy from './features/privacy_policy/pages/privacyPolicy';
import ContactForm from './features/contact_form/pages/contactForm';
import { colors } from './theme';

const urls = {
  login: '/login',
  overview: '/',
  missions: {
    landing: '/missions',
    create: '/missions/new',
    details: (id = ':id') => `/missions/${id}`,
    edit: (id = ':id') => `/missions/${id}/edit`,
  },
  targets: {
    landing: '/targets',
    import: '/targets/import',
    details: (id = ':id') => `/targets/${id}`,
  },
  zones: {
    landing: '/zones',
    create: '/zones/new',
    edit: (id = ':id') => `/zones/${id}/edit`,
  },
  crews: {
    landing: '/crews',
    create: '/crews/new',
    edit: (id = ':id') => `/crews/${id}/edit`,
  },
  admin: {
    landing: '/admin',
    users: {
      create: '/admin/users/new',
      edit: (id = ':id') => `/admin/users/${id}/edit`,
    },
    uav: {
      landing: '/admin/uav',
      create: '/admin/uav/new',
      edit: (id = ':id') => `/admin/uav/${id}/edit`,
    },
    ammunition: {
      landing: '/admin/ammunition',
      create: '/admin/ammunition/new',
      edit: (id = ':id') => `/admin/ammunition/${id}/edit`,
    },
    location: {
      landing: '/admin/location',
      create: '/admin/location/new',
      edit: (id = ':id') => `/admin/location/${id}/edit`,
    },
  },
};

function Router() {
  return (
    <Routes>
      <Route element={<PrivateRoute allowRoles={[role.ISTAR, role.BATTLE_CAPTAIN]} />}>
        <Route index element={<HeaderLayout><Overview /></HeaderLayout>} />
        <Route path="missions" element={<Outlet />}>
          <Route index element={<HeaderLayout><Missions /></HeaderLayout>} />
          <Route path="new" element={<CreateLayout><CreateMissions /></CreateLayout>} />
          <Route path=":id" element={<Outlet />}>
            <Route index element={<DetailsLayout><DetailsMission /></DetailsLayout>} />
            <Route path="edit" element={<CreateLayout><EditMission /></CreateLayout>} />
          </Route>
        </Route>

        <Route path="targets" element={<Outlet />}>
          <Route index element={<HeaderLayout><Targets /></HeaderLayout>} />
          <Route path="import" element={<CreateLayout><TargetsImport /></CreateLayout>} />
          <Route path=":id" element={<DetailsLayout><TargetDetails /></DetailsLayout>} />
        </Route>

        <Route path="zones" element={<Outlet />}>
          <Route index element={<HeaderLayout><Zones /></HeaderLayout>} />
          <Route path="new" element={<CreateLayout><CreateZone /></CreateLayout>} />
          <Route path=":id" element={<Outlet />}>
            <Route path="edit" element={<CreateLayout><EditZone /></CreateLayout>} />
          </Route>
        </Route>

        <Route path="crews" element={<Outlet />}>
          <Route index element={<HeaderLayout><Crews /></HeaderLayout>} />
          <Route path="new" element={<CreateLayout><CreateCrew /></CreateLayout>} />
          <Route path=":id" element={<Outlet />}>
            <Route path="edit" element={<CreateLayout><EditCrew /></CreateLayout>} />
          </Route>
        </Route>
      </Route>
      <Route element={<PrivateRoute allowRoles={[role.ADMIN]} />}>
        <Route path="admin" element={<Outlet />}>
          <Route index element={<HeaderLayout menu="admin"><Users /></HeaderLayout>} />
          <Route path="users">
            <Route index element={<CreateLayout><Users /></CreateLayout>} />
            <Route path="new" element={<CreateLayout><CreateUser /></CreateLayout>} />
            <Route path=":id/edit" element={<CreateLayout><CreateUser /></CreateLayout>} />
          </Route>
          <Route path="uav" element={<Outlet />}>
            <Route index element={<HeaderLayout menu="admin"><UAV /></HeaderLayout>} />
            <Route path="new" element={<CreateLayout><CreateUAV /></CreateLayout>} />
            <Route path=":id/edit" element={<CreateLayout><CreateUAV /></CreateLayout>} />
          </Route>
          <Route path="ammunition" element={<Outlet />}>
            <Route index element={<HeaderLayout menu="admin"><Ammunition /></HeaderLayout>} />
            <Route path="new" element={<CreateLayout><CreateAmmunition /></CreateLayout>} />
            <Route path=":id/edit" element={<CreateLayout><CreateAmmunition /></CreateLayout>} />
          </Route>
          <Route path="location" element={<Outlet />}>
            <Route index element={<HeaderLayout menu="admin"><Location /></HeaderLayout>} />
            <Route path="new" element={<CreateLayout><CreateLocation /></CreateLayout>} />
            <Route path=":id/edit" element={<CreateLayout><CreateLocation /></CreateLayout>} />
          </Route>
        </Route>
      </Route>
      <Route element={<PrivateRoute allowRoles={[role.CREW_MEMBER]} />}>
        <Route path="pilot" element={<Outlet />}>
          <Route path="missions" index element={<PilotsMissions />} />
          <Route path="crew" element={<PilotsCrew />} />
        </Route>
      </Route>
      <Route element={<PrivateRoute allowRoles={[role.AIR_DEFENCE]} />}>
        <Route path="air_defence" element={<Outlet />}>
          <Route path="alerts" index element={<HeaderLayout menu="air_defence" bgColor={colors.grayscale.b2} empty><AirDefenceAlerts /></HeaderLayout>} />
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/contact-form" element={<ContactForm />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default Router;
export { urls };
