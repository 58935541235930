import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  Dialog, Hidden, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IGetMissionListResponse, IMission } from 'types/mission';
import api, { apiURLs } from 'services/api';
import PageHeader from 'components/pageHeader';
import GroupBy from 'components/groupBy';
import MissionByStatus from 'features/missions/components/missionByStatus';
import HeaderLayout from 'components/header/headerLayout';
import CrewStatusSwitcher from 'features/missions/components/crewStatusSwitcher';
import { crewStatuses } from 'features/crews/services/constants';
import { extendMissionsWithZones } from 'features/missions/pages/utils';
import { ICrew, TCrewStatus } from 'types/crew';
import { useSearchParams } from 'react-router-dom';
import PilotsMissionDetails from '../components/pilotsMissionDetails';
import { colors } from '../../../theme';
import NoCrew from '../components/noCrew';

const groupByItems = ['status'];

function PilotsMissions() {
  const [groupedBy, setGroupedBy] = React.useState(groupByItems[0]);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');

  const [missions, setMission] = React.useState<IMission[]>([]);
  const [selectedMissionId, setSelectedMissionId] = useState<string | null>();

  const {
    data: zones = [],
  } = useQuery({
    queryKey: ['zone-list'],
    queryFn: (): Promise<any> => api.get(apiURLs.zones.list(null))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  const {
    data, isFetching,
  } = useQuery({
    queryKey: ['mission-list', search],
    queryFn: (): Promise<IGetMissionListResponse> => api.get(apiURLs.missions.my(search))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  React.useEffect(() => {
    if (data?.results && !isFetching) {
      setMission(extendMissionsWithZones(data.results, zones.results));
    }
  }, [data, zones.results, isFetching]);

  const {
    data: crew,
  } = useQuery({
    queryKey: ['my-crew'],
    queryFn: (): Promise<ICrew> => api.get(apiURLs.crews.my)
      .then((res) => res.data)
      .catch((err) => {
        console.error(err);
      }),
  });

  const selectMissionAndScrollTop = (mission: IMission) => {
    if (selectedMissionId === mission.id) {
      setSelectedMissionId(null);
    } else {
      setSelectedMissionId(mission.id);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  function onGroupByChange(group: string) {
    setGroupedBy(group);
    setSelectedMissionId(null);
  }

  if (!crew) {
    return (
      <HeaderLayout
        menu="pilot"
        empty
        statusSwitcher={<Typography color={colors.grayscale.g1}>{t('you_dont_have_crew')} 😢</Typography>}
      >
        <NoCrew />
      </HeaderLayout>
    );
  }

  const selectedMission = missions.find((m: IMission) => m.id === selectedMissionId);

  return (
    <HeaderLayout
      menu="pilot"
      empty={!crew}
      statusSwitcher={(
        <CrewStatusSwitcher
          crew={crew}
          options={crewStatuses as TCrewStatus[]}
        />
      )}
    >
      <PageHeader title={t('my_missions')} showDatePicker />
      <GroupBy
        items={groupByItems}
        selected={groupedBy}
        onChange={(group: string) => onGroupByChange(group)}
        hideLabel
      />
      <Stack flexDirection="row" gap={2}>
        <Stack gap={1} flex={1}>
          {groupedBy === 'status' && (
            <MissionByStatus
              missions={missions}
              selectedMission={selectedMission}
              selectMissionAndScrollTop={selectMissionAndScrollTop}
              isFetching={isFetching}
            />
          )}
        </Stack>
        <Hidden mdDown>
          {selectedMission && (
            <PilotsMissionDetails
              mission={selectedMission}
              setSelectedMission={setSelectedMissionId}
            />
          )}
        </Hidden>
        <Hidden mdUp>
          <Dialog open={!!selectedMission} fullScreen>
            {selectedMission && (
              <PilotsMissionDetails
                mission={selectedMission}
                setSelectedMission={setSelectedMissionId}
              />
            )}
          </Dialog>
        </Hidden>
      </Stack>
    </HeaderLayout>
  );
}

export default PilotsMissions;
