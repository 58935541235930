import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../theme';
import { IEventLogEntry, IPlaceEventLogEntry } from '../../../types/eventlog';
import RequestCard from './requestCard';

const Wrapper = styled(Box)`
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-end;
  background-color: ${colors.grayscale.b3};
  border: 1px solid ${colors.grayscale.b4};
  padding: 20px;
`;

interface Props {
    isLoading: boolean;
    requests: IPlaceEventLogEntry[];
    threats: IEventLogEntry[];
    onChange: (entityId: string, danger: boolean) => void;
}

function Requests({
  isLoading, requests, threats, onChange,
}: Props) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Typography variant="h2" color={colors.grayscale.w0}>{t('requests')}</Typography>
      {requests.map((request) => (
        <RequestCard
          request={request}
          danger={!!threats
            ?.some((threat: IEventLogEntry) => threat.entity_id === request.entity_id)}
          onChange={onChange}
        />
      ))}
    </Wrapper>
  );
}

export default Requests;
