import React from 'react';
import { styled, Switch, SwitchProps } from '@mui/material';
import { colors } from 'theme';

interface DangerSwitcherProps extends SwitchProps {
  switchSize?: 'big' | 'small';
}

const DangerSwitcher = styled(({ ...props }: DangerSwitcherProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, switchSize = 'big' }) => ({
  width: switchSize === 'big' ? 106 : 78,
  height: switchSize === 'big' ? 61 : 45,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: switchSize === 'big' ? 8 : 6,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: switchSize === 'big' ? 'translateX(46px)' : 'translateX(31px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: colors.system.notification,
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: colors.system.notification,
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '.MuiSwitch-thumb': {
        color: colors.grayscale.b2,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: colors.grayscale.b2,
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    color: '#757575',
    width: switchSize === 'big' ? 45 : 33,
    height: switchSize === 'big' ? 45 : 33,
  },
  '& .MuiSwitch-track': {
    borderRadius: switchSize === 'big' ? 61 / 2 : 45 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));

export default DangerSwitcher;
