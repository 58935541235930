import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { urls } from 'router';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Container, Stack } from '@mui/material';

import FakeModal from 'components/fakeModal';
import api, { apiURLs } from 'services/api';
import { BaseFilters } from 'services/model';
import { dayjs } from 'services/dayjs';
import { IGetTargetListResponse, ITarget } from 'types/target';
import { ICrew, IGetCrewListResponse } from 'types/crew';
import { IMissionDto } from 'types/mission';
import { getValidationErrors, IError } from 'services/helpers';
import { IGetZoneListResponse, IZone } from 'types/zone';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { crewTypes } from 'features/crews/services/constants';
import MissionForm from '../components/missionForm';
import TargetSelect from '../components/targetSelect';

const defaultMission: IMissionDto = {
  name: '',
  type: crewTypes.RECON,
  due_date: dayjs(),
  status: 'planned',
  crew: null,
  report: '',
  target: [],
  ammo: [],
  description: '',
};

const missionSchema = yup.object({
  type: yup.string().required('type_required'),
  target: yup.array().when('type', (type, schema) => (type[0] === crewTypes.RECON ? schema : schema.min(1, 'please_select_mission_targets'))),
  zones: yup.array().when('type', (type, schema) => (type[0] === crewTypes.STRIKE ? schema : schema.required('select_zones').min(1, 'select_zones'))),
  nais: yup.array().when('type', (type, schema) => (type[0] === crewTypes.STRIKE ? schema : schema.required('select_nais').min(1, 'select_nais'))),
  crew: yup.object().required('please_select_mission_crew'),
}).required();

function CreateMission() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const [showTargetsSelect, setShowTargetsSelect] = useState(false);
  const [mission, setMission] = React.useState<IMissionDto>(defaultMission);
  const [crewsList, setCrewsList] = React.useState<ICrew[]>();
  const [targetsList, setTargetsList] = React.useState<ITarget[]>();
  const [zonesList, setZonesList] = React.useState<IZone[]>();
  const [errors, setErrors] = React.useState<IError>({});

  const search = new URLSearchParams(location.search).get('search');
  const filters = { page: 1, limit: 100, search } as BaseFilters;

  const {
    data: crews, isFetching: isFetchingCrews,
  } = useQuery({
    queryKey: ['crew-list'],
    queryFn: (): Promise<IGetCrewListResponse> => api.get(apiURLs.crews.list(null))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  useEffect(() => {
    if (crews?.results && !isFetchingCrews) setCrewsList(crews.results);
  }, [crews, isFetchingCrews]);

  const {
    data: targets, isFetching: isFetchingTargets,
  } = useQuery({
    queryKey: ['target-list', filters],
    queryFn: (): Promise<IGetTargetListResponse> => api.get(apiURLs.targets.list(filters))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  useEffect(() => {
    if (targets?.results && !isFetchingTargets) setTargetsList(targets.results);
  }, [targets, isFetchingTargets]);

  const {
    mutate: submit, reset, isPending,
  } = useMutation({
    mutationKey: ['submit-mission'],
    mutationFn: (c: any) => (api.post(apiURLs.missions.create, c)
      .then(() => {
        reset();
        navigate(urls.missions.landing);
        toast.success(t('mission_created_successfully'));
      })
      .catch((err) => toast.error(err.response.data?.error))
    ),
  });

  const toggleTargetSelect = () => setShowTargetsSelect(!showTargetsSelect);

  const onTargetClick = (target: ITarget) => {
    if (mission) {
      if (!mission.target.find((tg) => tg.id === target.id)) {
        setMission({ ...mission, target: [...mission.target, target] });
        setTargetsList((prevState) => prevState?.filter((tg) => tg.id !== target.id));
      } else {
        setMission({
          ...mission,
          target: mission.target.filter((tg) => tg.id !== target.id),
        });
        if (targetsList) setTargetsList([...targetsList, target]);
      }
    }
  };

  const {
    data: zones, isFetching: isFetchingZones,
  } = useQuery({
    queryKey: ['zone-list'],
    queryFn: (): Promise<IGetZoneListResponse> => api.get(apiURLs.zones.list(null))
      .then((res) => res.data)
      .catch((err) => toast.error(err.response.data?.message)),
  });

  useEffect(() => {
    if (zones?.results && !isFetchingZones) setZonesList(zones.results);
  }, [zones, isFetchingZones]);

  const prepareCreateMissionDto = (m: IMissionDto) => ({
    ...m,
    target: m.target.map((target: ITarget) => target.id),
    crew: m.crew?.id,
    ammo: m.ammo?.map((a) => a.id),
  });

  const validateAndSubmit = (m: IMissionDto, schema: any) => {
    schema.validate(m, { abortEarly: false }).then(() => {
      setErrors({});
      submit(prepareCreateMissionDto(mission));
    }).catch((err: ValidationError) => {
      setErrors(getValidationErrors(err));
    });
  };

  return (
    <Container maxWidth="xl">
      <FakeModal
        cancel={() => navigate(urls.missions.landing)}
        title={t('create_new_mission')}
        hideControls
      >
        <Stack flexDirection="row" gap={2}>
          <Stack gap={1} flex={1}>
            <MissionForm
              showTargetsSelect={showTargetsSelect}
              setShowTargetsSelect={() => toggleTargetSelect()}
              mission={mission}
              setMission={setMission}
              crews={crewsList}
              zones={zonesList}
              onTargetClick={onTargetClick}
              errors={errors}
            />
            <Stack direction="row" gap={2} mt={2}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => validateAndSubmit(mission, missionSchema)}
                disabled={isPending}
              >
                {t('save')}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClick={() => navigate(urls.missions.landing)}
              >
                {t('cancel')}
              </Button>
            </Stack>
          </Stack>
          {showTargetsSelect ? (
            <TargetSelect
              onClose={setShowTargetsSelect}
              targets={targetsList}
              onTargetClick={onTargetClick}
            />
          ) : null}
        </Stack>
      </FakeModal>
    </Container>
  );
}

export default CreateMission;
