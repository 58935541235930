import { IMission } from 'types/mission';
import { INAI, IZone } from 'types/zone';

export const extendMissionsWithZones = (
  missions: IMission[],
  zones: IZone[],
) => {
  const missionsWithZones = missions.map((mission) => {
    const { nais } = mission;
    // eslint-disable-next-line
    const uniqueNAIs = (nais || []).reduce((acc: INAI[], current: INAI) => {
      const zoneIds = acc.map((nai) => nai.zoneId);

      if (!zoneIds.includes(current.zoneId)) {
        acc.push(current);
      }
      return acc;
    }, []);

    // eslint-disable-next-line
    const missionZones = uniqueNAIs.map(({ zoneId }) => zones?.filter((zone: IZone) => zoneId && zone.id === zoneId)[0]);

    return { ...mission, zones: missionZones };
  });

  return missionsWithZones;
};

export const mergeArraysById = (combined: any[]): any[] => {
  const merged = combined.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  return Object.values(merged);
};

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    return keys1.every((key) => keys2.includes(key) && deepEqual(obj1[key], obj2[key]));
  }
  return obj1 === obj2;
};

export default {
  extendMissionsWithZones,
};
