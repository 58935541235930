import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function PrivacyPolicy() {
  return (
    <Box
      sx={{
        backgroundColor: '#171717',
        color: '#FFFFFF',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 4,
      }}
    >
      <Container
        maxWidth="md"
        // eslint-disable-next-line
        sx={{ mt: 4, mb: 4, backgroundColor: '#171717', color: '#FFFFFF' }}
      >
        <Box mb={4}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
          >
            Effective Date: January 1, 2024
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
          >
            1. Information We Collect
          </Typography>

          <Typography
            variant="h6"
            component="h3"
            gutterBottom
          >
            1.1 Information You Provide
          </Typography>
          <Typography
            variant="body1"
            paragraph
          >
            We may collect the following personal information that you provide
            to us:
          </Typography>
          <ul>
            <Typography component="li">
              Name, email address, phone number, and other contact details.
            </Typography>
            <Typography component="li">
              Account information, such as your username and password.
            </Typography>
            <Typography component="li">
              Feedback, inquiries, or other communications you send to us.
            </Typography>
          </ul>

          <Typography
            variant="h6"
            component="h3"
            gutterBottom
          >
            1.2 Information We Collect Automatically
          </Typography>
          <Typography
            variant="body1"
            paragraph
          >
            When you use the App or Services, we may automatically collect:
          </Typography>
          <ul>
            <Typography component="li">
              Device Information: Details about your device, such as IP address,
              operating system, and browser type.
            </Typography>
            <Typography component="li">
              Usage Data: Information about your interactions with the App, such
              as pages viewed, features used, and time spent on the App.
            </Typography>
            <Typography component="li">
              Cookies and Tracking Technologies: We may use cookies and similar
              technologies to track your activity and preferences.
            </Typography>
          </ul>
        </Box>

        <Box mb={3}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
          >
            2. How We Use Your Information
          </Typography>
          <Typography
            variant="body1"
            paragraph
          >
            We use your information to:
          </Typography>
          <ul>
            <Typography component="li">
              Provide, maintain, and improve the App and Services.
            </Typography>
            <Typography component="li">
              Personalize your experience within the App.
            </Typography>
            <Typography component="li">
              Communicate with you, including sending updates, notifications,
              and promotional content.
            </Typography>
            <Typography component="li">
              Analyze usage patterns and improve our offerings.
            </Typography>
            <Typography component="li">
              Comply with legal obligations and protect our rights.
            </Typography>
          </ul>
        </Box>

        <Box mb={3}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
          >
            3. Contact Us
          </Typography>
          <Typography
            variant="body1"
            paragraph
          >
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
          </Typography>
          <Typography variant="body1">
            <strong>STS Support Team</strong>
          </Typography>
          <Typography variant="body1">Email: support@stsapp.com</Typography>
          <Typography variant="body1">Address: Maidan Nezalezhnosti, 1, Kyiv</Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default PrivacyPolicy;
