import axios from 'axios';
import { IMissionsFilterDto } from 'types/mission';
import { BaseFilters } from './model';
import { filterAndSimplifyObject, toQueryString } from './helpers';

export const apiURLs = {
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000',
  auth: {
    login: '/auth/login',
    refreshToken: '/auth/refresh-token',
    profile: '/auth/profile',
  },
  admin: {
    users: {
      list: (filters: BaseFilters) => `/user${toQueryString(filters)}`,
      create: '/user',
      patch: (id: string) => `/user/${id}`,
      updatePassword: (id: string) => `/user/${id}/set-password`,
      details: (id: string) => `/user/${id}`,
    },
    uavs: {
      list: (filters: BaseFilters) => `/uav${toQueryString(filters)}`,
      create: '/uav',
      patch: (id: string) => `/uav/${id}`,
      details: (id: string) => `/uav/${id}`,
      delete: (id: string) => `/uav/${id}`,
    },
    ammunition: {
      list: (filters: BaseFilters) => `/ammunition${toQueryString(filters)}`,
      create: '/ammunition',
      patch: (id: string) => `/ammunition/${id}`,
      details: (id: string) => `/ammunition/${id}`,
      delete: (id: string) => `/ammunition/${id}`,
    },
    location: {
      list: (filters: BaseFilters) => `/location${toQueryString(filters)}`,
      create: '/location',
      patch: (id: string) => `/location/${id}`,
      details: (id: string) => `/location/${id}`,
      delete: (id: string) => `/location/${id}`,
    },
  },
  missions: {
    list: (search: string | null, page: number | null = 1, limit: number | null = 50, filters: IMissionsFilterDto | null = null) => `/mission${toQueryString({
      // eslint-disable-next-line max-len
      page, limit, search, filters: JSON.stringify(filterAndSimplifyObject({ ...filters, crew: filters?.crew?.id }) || {}),
    })}`,
    my: (search: string | null, page = 1, limit = 50) => `/mission/my${toQueryString({ page, limit, search })}`,
    details: (id: string) => `/mission/${id}`,
    audit: (id: string) => `/mission/${id}/audit`,
    create: '/mission',
    updateStatus: (id: string) => `/mission/${id}/status`,
    updateMission: (id: string) => `/mission/${id}`,
    updateMissionChecklist: (id: string) => `/mission/${id}/checklist`,
    finishMission: (id: string) => `/mission/${id}/finish`,
  },
  crews: {
    list: (search: string | null, page = 1, limit = 50) => `/crew${toQueryString({ page, limit, search })}`,
    listExtended: (search: string | null, page = 1, limit = 50) => `/crew/extended${toQueryString({ page, limit, search })}`,
    details: (id: string | null) => `/crew/${id}`,
    create: '/crew',
    updateCrew: (id: string) => `/crew/${id}`,
    my: '/crew/my',
    status: (id: string) => `/crew/${id}/status`,
  },
  targets: {
    list: (filters: BaseFilters) => `/target${toQueryString(filters)}`,
    details: (id: string) => `/target/${id}`,
    import: '/target/import',
    archive: (id: string) => `/target/${id}/archive`,
  },
  favorites: {
    list: () => '/favorite',
    toggle: () => '/favorite',
  },
  zones: {
    list: (search: string | null, page = 1, limit = 50) => `/zone${toQueryString({ page, limit, search })}`,
    listAAD: (search: string | null, page = 1, limit = 50) => `/zone/aad${toQueryString({ page, limit, search })}`,
    details: (id: string) => `/zone/${id}`,
    listExtended: (filters: BaseFilters) => `/zone/extended${toQueryString(filters)}`,
    create: '/zone',
    update: (id: string) => `/zone/${id}`,
    updateZone: (id: string) => `/zone/${id}`,
  },
  nais: {
    create: '/nai',
    update: (id: string) => `/nai/${id}`,
    delete: (id: string) => `/nai/${id}`,
  },
  eventlog: {
    threat: '/eventlog/threat',
    create: '/eventlog',
    placeRequests: '/eventlog/request/place',
  },
  count: '/count',
};

const api = axios.create({
  baseURL: apiURLs.baseURL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    config.headers.Accept = 'application/json';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401
        && !originalRequest.retryReq
        && originalRequest.url !== apiURLs.auth.login
    ) {
      originalRequest.retryReq = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await api.post(apiURLs.auth.refreshToken, { refreshToken });
        const { token } = response.data;

        localStorage.setItem('authToken', token);

        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axios(originalRequest);
      } catch (resError) {
        localStorage.removeItem('user');
        localStorage.removeItem('authToken');
        window.location.replace('/');
      }
    }

    return Promise.reject(error);
  },
);

export default api;
