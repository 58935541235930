import React from 'react';
import { Paper, styled } from '@mui/material';
import { colors } from 'theme';

const CardBox = styled(Paper)<{ danger?: boolean, borderRadius: number, padding: number }>`
  border-radius: ${({ borderRadius }) => borderRadius}px;
  background-color: ${({ danger }) => (danger ? colors.system.red : colors.grayscale.b3)};
  border: ${({ danger }) => `1px solid ${(danger ? colors.mission.strike['500'] : colors.grayscale.b3)}`};
  padding: ${({ padding }) => padding}px;
  box-shadow: none;
  color: ${({ danger }) => (danger ? colors.grayscale.b2 : colors.grayscale.w1)};
`;

interface Props {
  danger: boolean;
  borderRadius?: number;
  padding?: number;
  children: React.ReactNode;
}

function DangerTile({
  danger, borderRadius = 16, padding = 24, children,
}: Props) {
  return (
    <CardBox danger={danger} borderRadius={borderRadius} padding={padding}>
      {children}
    </CardBox>

  );
}

DangerTile.defaultProps = {
  borderRadius: 16,
  padding: 24,
};

export default DangerTile;
