import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { IPlaceEventLogEntry } from '../../../types/eventlog';
import { colors } from '../../../theme';
import { getRequestDate } from '../../../services/dayjs';
import DangerSwitcher from './dangerSwitcher';
import DangerTile from './dangerTile';

const CardBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
`;

interface Props {
    request: IPlaceEventLogEntry;
    danger: boolean;
    onChange: (entityId: string, danger: boolean) => void;
}

function RequestCard({ request, danger, onChange }: Props) {
  const { t, i18n } = useTranslation();
  const name = i18n.language === 'uk' ? request?.place?.name?.uk : request?.place?.name?.en;
  return (
    <DangerTile danger={danger} padding={16} borderRadius={24}>
      <CardBox>
        <Box display="flex" gap={1} alignItems="center">
          <Typography color={danger ? 'rgba(0, 0, 0, 0.5)' : 'secondary'}>{getRequestDate(request.created_at)}</Typography>
          <Typography variant="body2" color={colors.system.yellow0}>({dayjs(request.created_at).fromNow(false)})</Typography>
        </Box>
        <Typography variant="h3" color={danger ? colors.grayscale.b2 : colors.grayscale.w0}>{name}</Typography>
        <Typography variant="body2" color={danger ? 'rgba(0, 0, 0, 0.5)' : 'secondary'}>{t('from')} {request.created_by}</Typography>
        <Box display="flex" gap={2}>
          <DangerSwitcher switchSize="small" checked={danger} onClick={() => onChange(request.entity_id, !danger)} />
          <Typography color={danger ? colors.grayscale.b1 : colors.grayscale.w1} mt={2}>{danger ? t('there_is_a_threat') : t('no_threat')}</Typography>
        </Box>
      </CardBox>
    </DangerTile>
  );
}

export default RequestCard;
