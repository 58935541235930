import React from 'react';
import { Box, MenuItem, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { colors } from 'theme';
import { useAuth } from 'features/login/authProvider';

export default function RoleSwitcher() {
  const { t } = useTranslation();
  const { user, switchRole } = useAuth();
  console.log(user);

  if (!user?.role) return null;

  return (
    <div>
      <MenuItem disableRipple disabled>
        <Box pb={2}>
          <Typography variant="body2">{t('your_roles')}</Typography>
        </Box>
      </MenuItem>
      {user.role.map((role) => {
        const isCurrent = user?.currentRole === role;
        return (
          <MenuItem
            key={role}
            onClick={() => switchRole(role)}
            disableRipple
            sx={{ marginBottom: 2 }}
          >
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography variant="body1" color={isCurrent ? undefined : 'secondary'}>{t(role)}</Typography>
              {isCurrent && (
                <Check fontSize="small" sx={{ color: colors.system.g1 }} />
              )}
            </Box>
          </MenuItem>
        );
      })}
    </div>
  );
}
